const TableData = {
  LiveActionTelevisionseries: {
    id: 1,
    title: "OTT Series",
    columns: [
      {
        name: "Program title",
        selector: (row) => row.program,
        sortable: true,
      },
      {
        name: "Actor",
        selector: (row) => row.actor,
        sortable: true,
      },
      {
        name: "Character",
        selector: (row) => row.character,
        sortable: true,
      },
      {
        name: "Dub Language",
        selector: (row) => row.language,
        sortable: true,
      },
    ],
    data: [
      {
        id: 1,
        program: "The Falcon and The Winter Soldier",
        actor: "Anthony Mackie",
        character: "Sam Wilson (Marvel Cinematic Universe)",
        language: "Hindi",
      },
      {
        id: 2,
        program: "House of Card",
        actor: "Mahershala Ali",
        character: "Remy Danton",
        language: "Hindi",
      },

      {
        id: 3,
        program: "Reacher",
        actor: "Malcolm Goodwin",
        character: "Oscar Finlay",
        language: "Hindi",
      },
    ],
  },

  Liveactionfilms: {
    id: 2,
    title: "Live action films",
    columns: [
      {
        name: "Program title",
        selector: (row) => row.program,
        sortable: true,
      },
      {
        name: "Actor",
        selector: (row) => row.actor,
        sortable: true,
      },
      {
        name: "Character",
        selector: (row) => row.character,
        sortable: true,
      },
      {
        name: "Dub Language",
        selector: (row) => row.language,
        sortable: true,
      },
    ],
    data: [
      {
        id: 1,
        program: "FM Fun Aur Masti",
        actor: "Mahershala Ali",
        character: "Hussain",
        language: "Dakhni",
      },
      {
        id: 2,
        program: "Eega",
        actor: "Nani",
        character: "Nani (Jani in Hindi version)",
        language: "Hindi",
      },
      {
        id: 3,
        program: "The Admiral: Roaring Currents",
        actor: "Unknown actor",
        character: "Unknown character",
        language: "Hindi English",
      },
      {
        id: 4,
        program: "Roar: Tigers of the Sundarbans",
        actor: "Aadil Chahal",
        character: "Kashmiri",
        language: "English",
      },
      {
        id: 5,
        program: "Jason Bourne",
        actor: "Ato Essandoh",
        character: "Craig Jeffers",
        language: "Hindi",
      },
      {
        id: 6,
        program: "Morgan",
        actor: "Boyd Holbrook",
        character: "Skip Vronsky",
        language: "Hindi",
      },
      {
        id: 7,
        program: "Ghostbusters",
        actor: "Chris Hemsworth",
        character: "Kevin Beckman",
        language: "Hindi",
      },
      {
        id: 8,
        program: "Passengers",
        actor: "Michael Sheen",
        character: "Arthur",
        language: "Hindi",
      },
      {
        id: 9,
        program: "Star Wars: The Last Jedi",
        actor: "John Boyega",
        character: "Finn",
        language: "Hindi",
      },
      {
        id: 10,
        program: "Captain America: The Winter Soldier",
        actor: "Anthony Mackie",
        character: "Sam Wilson / Falcon",
        language: "Hindi",
      },
      {
        id: 11,
        program: "Poorna: Courage Has No Limit",
        actor: "",
        character: "Newsreader",
        language: "Hindi",
      },
      {
        id: 12,
        program: "Avengers: Infinity War",
        actor: "Anthony Mackie",
        character: "Sam Wilson / Falcon",
        language: "Hindi",
      },
      {
        id: 13,
        program: "Avengers: Endgame",
        actor: "Anthony Mackie",
        character: "Sam Wilson / Falcon",
        language: "Hindi",
      },
      {
        id: 14,
        program: "RRR",
        actor: "Edward Sonnenblick",
        character: "Edward",
        language: "Hindi",
      },
      {
        id: 15,
        program: "777 Charlie",
        actor: "Danish Sait",
        character: "Karshan Roy",
        language: "Hindi, Telugu, Tamil, Malayalam",
      },
      {
        id: 16,
        program: "Vijayanand",
        actor: "",
        character: "",
        language: "Hindi, Telugu, ",
      },
      {
        id: 17,
        program: "Music School (film)",
        actor: "Shaan (singer)",
        character: "Albert",
        language: "",
      },
    ],
  },

  Podcasts: {
    id: 3,
    title: "Podcasts",
    columns: [
      { name: "Year", selector: (row) => row.year, sortable: true },
      { name: "Title", selector: (row) => row.title, sortable: true },
      { name: "Topic", selector: (row) => row.topic, sortable: true },
      { name: "Language", selector: (row) => row.language, sortable: true },
      { name: "Producer", selector: (row) => row.producer, sortable: true },
    ],
    data: [
      {
        id: 1,
        year: "2022",
        title: "EdTalk with Anuj!",
        topic: "Education",
        language: "English",
        producer: "Anuj Gurwara",
      },
      {
        id: 2,
        year: "2023",
        title: "Bhoot Bhulaiya",
        topic: "Horror Stories",
        language: "Hindi, English",
        producer: "Kommune",
      },
    ],
  },
  RadioShows: {
    id: 4,
    title: "Radio Shows",
    columns: [
      { name: "Year", selector: (row) => row.year, sortable: true },
      { name: "Station", selector: (row) => row.station, sortable: true },
      { name: "Show Name", selector: (row) => row.show, sortable: true },
    ],
    data: [
      {
        id: 1,
        year: "2005",
        station: "All India Radio",
        show: "Yuvavani English",
      },
      {
        id: 2,
        year: "2005",
        station: "Radio Biryani",
        show: "Naach Meri Jaan Aashiq Awara",
      },
      {
        id: 3,
        year: "2006–2008",
        station: "Radio City 91.1FM",
        show: "Recharge! City Joyride The Radio City Breakfast Show The Great Indian Countdown",
      },
      {
        id: 4,
        year: "2012-2013",
        station: "Radiowalla.in",
        show: "Matinee Masala",
      },
    ],
  },

  Awards: {
    id: 5,
    title: "Awards",
    columns: [
      { name: "Awards for Radio", selector: (row) => row.awards },
      { name: "Company", selector: (row) => row.company },
    ],
    data: [
      {
        id: 1,
        awards: "2005 - Best RJ (Hindi)",
        company: "Radio Lovers Association of Hyderabad - Radio Biryani",
      },
      {
        id: 2,
        awards: "2007 - Best RJ (Male)",
        company:
          "All India Radio City stations - Radio City 91.1fm - Radio City 91.1fm",
      },
      {
        id: 3,
        awards: "2008 - India's Best Radio Host",
        company: "Radio Duniya Magazine - Radio City 91.1fm",
      },
    ],
  },
};

export default TableData;
