import React, { useState, useEffect } from "react";

import { NavLink, Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import "./header.css";
import "../Component.css";
import logo from "../images/logo.png";

//import logo from "../../img/logo.png";
const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };
  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className={scrolled ? "navbar scrolled" : "navbar"}>
        <div className="wrapper">
          <div className="header-new-wrap">
            <div className="header-new-logo">
              <NavLink exact activeClassName="is-active" to="/">
                <img
                  src={logo}
                  alt="Logo"
                  onClick={scrollToTop}
                  className={scrolled ? "logo scrolled" : "logo"}
                />
              </NavLink>
            </div>
            <div className="header-new-menu">
              <nav className="main-nav ">
                {/* Responsive Menu Button */}
                {isResponsiveclose === true ? (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      <FiXCircle />
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      <FiAlignRight />
                    </span>
                  </>
                )}
                <ul className={boxClass.join(" ")}>
                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/`}
                    >
                      HOME
                    </NavLink>
                  </li>

                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <Link to="#">
                      WORK <FiChevronDown />
                    </Link>
                    <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Master-of-Ceremonies`}
                        >
                          Master of Ceremonies
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Singer`}
                        >
                          Singer
                        </NavLink>{" "}
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Communications-Coach`}
                        >
                          {" "}
                          Communications Coach{" "}
                        </NavLink>{" "}
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Voice-Artist `}
                        >
                          {" "}
                          Voice Artist{" "}
                        </NavLink>{" "}
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Actor `}
                        >
                          {" "}
                          Actor{" "}
                        </NavLink>{" "}
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Content-Creator `}
                        >
                          {" "}
                          Content Creator{" "}
                        </NavLink>{" "}
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item " onClick={scrollToTop}>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/Media`}
                    >
                      MEDIA
                    </NavLink>
                  </li>
                  <li className="menu-item " onClick={scrollToTop}>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/Contact`}
                    >
                      CONTACT
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
