import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SingerData from "./SingerData";
import VoiceArtistTable from "./VoiceArtistTable";

import gallery1 from "./images/gallery-img-1.png";
import ms1 from "./images/ms-1.png";
import ms2 from "./images/ms-2.png";
import ms3 from "./images/ms-3.png";
import ms4 from "./images/ms-4.png";
import ms5 from "./images/ms-5.png";
import ms6 from "./images/ms-6.png";
import ms7 from "./images/ms-7.png";
import ms8 from "./images/ms-8.png";
import ms9 from "./images/ms-8.png";
import Awads from "./images/awads-1.png";

const containerVariants = {
  hidden: { opacity: 0, y: "100px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6, // Duration of the transition in seconds
      ease: "easeOut", // Type of easing function
    },
  },
};

const talentedContainers2Variants = {
  hidden: { opacity: 0, y: "50px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "linear",
      duration: 2,
      x: { duration: 1 },
    },
  },
};

const imageVariants = {
  hover: { scale: 1.1, opacity: 0.8 },
};

const imageVariants2 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};

const imageVariants3 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};
function VoiceArtist() {
  const [refFirstGroup, inViewFirstGroup] = useInView({ threshold: 0.1 });
  const [refSecondGroup, inViewSecondGroup] = useInView({ threshold: 0.1 });
  const [refThirdGroup, inViewThirdGroup] = useInView({ threshold: 0.1 });
  const [refFourGroup, inViewFourGroup] = useInView({ threshold: 0.1 });
  const [refFiveGroup, inViewFiveGroup] = useInView({ threshold: 0.1 });
  const [refEightGroup, inViewEightGroup] = useInView({ threshold: 0.1 });
  const [refNineGroup, inViewNineGroup] = useInView({ threshold: 0.1 });

  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper">
          <h1>Voice Artist</h1>
          <p>
            A former RJ and highly experienced voice artist, Anuj continues to
            lend his versatile voice to a wide array of productions. His
            impressive portfolio includes TV commercials, corporate films,
            jingles, audiobooks, e-learning modules, video game characters, and
            dubbed movie projects
          </p>
          <div className="voice-artist-wrap">
            <h3>Notable projects:</h3>
            <div className="bullet-2">
              <ul>
                <li>
                  Official Hindi voice for The Falcon (Marvel/Avengers
                  superhero) 2014-present. 
                </li>
                <li>
                  Hindi voice of Mahershala Ali (Remy Danton; House of Cards)
                </li>
                <li>
                  English & Hindi voice of the title character - Hanuman - in
                  the first-ever Indian game for Sony Playstation 2: Hanuman
                </li>
              </ul>
            </div>

            <h4>
              <b>Brands include</b> Marvel Comics, Amazon, SBI Life, Red Bull,
              Club Mahindra, Godrej, Carwale.com, Tata Motors, Juggernaut Books,
              Telangana Tourism <b>and many more.</b>
            </h4>
          </div>
          <div className="image-box-bg">
            <h2>Videos</h2>
            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refSecondGroup}
                initial="hidden"
                animate={inViewSecondGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/UdCXwM6xOWk?si=1lCZPxqcy9xmhdIH"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/T-wCBurMFrs?si=QtR2gEXTMqFtS_aN"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/ERRADfTAtvs?si=7qL0d_CkHsms2IHj"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={3}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/x0oazB-KjgE?si=0ldbR_apWbp0a0L6"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={4}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/lNlSsw7J1pA?si=lYJDmcv53AZac1T4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={5}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/Yg7jpJUUllE?si=8gQU8K0EZy8GrvlO"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>
          <h2>Dubbing Roles</h2>
          <VoiceArtistTable />
        </div>
      </div>
    </div>
  );
}

export default VoiceArtist;
