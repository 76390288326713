import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import MasterCeremonies from "./images/master-of-ceremonies.png";
import Mediav1 from "./images/media-v-1.png";
import Mediav2 from "./images/media-v-2.png";
import Mediav3 from "./images/media-v-3.png";
import Mediav4 from "./images/media-v-4.png";
import Mediav5 from "./images/media-v-5.png";

import MediaGallery from "./MediaGallery";

const containerVariants = {
  hidden: { opacity: 0, y: "100px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6, // Duration of the transition in seconds
      ease: "easeOut", // Type of easing function
    },
  },
};

const talentedContainers2Variants = {
  hidden: { opacity: 0, y: "50px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "linear",
      duration: 2,
      x: { duration: 1 },
    },
  },
};

const imageVariants = {
  hover: { scale: 1.1, opacity: 0.8 },
};

const imageVariants2 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};

const imageVariants3 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};

function MediaPage() {
  const [refFirstGroup, inViewFirstGroup] = useInView({ threshold: 0.1 });
  const [refSecondGroup, inViewSecondGroup] = useInView({ threshold: 0.1 });
  const [refThirdGroup, inViewThirdGroup] = useInView({ threshold: 0.1 });
  const [refFourGroup, inViewFourGroup] = useInView({ threshold: 0.1 });
  const [refFiveGroup, inViewFiveGroup] = useInView({ threshold: 0.1 });
  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper">
          <h1>Media</h1>

          <motion.div
            className="media-talented-s-wrap"
            ref={refSecondGroup}
            initial="hidden"
            animate={inViewSecondGroup ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <motion.div
              className="media-talented-s-card "
              custom={0}
              variants={imageVariants2}
            >
              <img src={Mediav1} alt="Mediav1" />
              <h3>
                Hyderabad artiste's rant about Bengaluru traffic goes viral.
                Watch
              </h3>
              <button>
                <a
                  href="https://www.hindustantimes.com/cities/bengaluru-news/hyderabad-artiste-s-rant-about-bengaluru-traffic-goes-viral-watch-101658905369694.html"
                  target="_blank"
                >
                  Open Article
                </a>
              </button>
            </motion.div>

            <motion.div
              className="media-talented-s-card "
              custom={1}
              variants={imageVariants2}
            >
              <img src={Mediav2} alt="Mediav2" />
              <h3>
                Influencers, memes & WhatsApp — social media war rooms are
                frontline in battle for Telangana
              </h3>
              <button>
                <a
                  href="https://theprint.in/politics/influencers-memes-whatsapp-social-media-war-rooms-are-frontline-in-battle-for-telangana/1863808/"
                  target="_blank"
                >
                  Open Article
                </a>
              </button>
            </motion.div>

            <motion.div
              className="media-talented-s-card "
              custom={2}
              variants={imageVariants2}
            >
              <img src={Mediav3} alt="Mediav3" />
              <h3>
                Man Takes A Hilarious Jibe Against People Who Obsess Over
                Bangalore Weather In Video
              </h3>
              <button>
                <a
                  href="https://www.storypick.com/man-takes-a-hilarious-jibe-against-bangalore-weather/"
                  target="_blank"
                >
                  Open Article
                </a>
              </button>
            </motion.div>

            <motion.div
              className="media-talented-s-card "
              custom={3}
              variants={imageVariants2}
            >
              <img src={Mediav4} alt="Mediav4" />
              <h3>
                Hyderabad artiste Anuj Gurwara takes a dig at Delhiites braving
                coldest winter in 10 years
              </h3>
              <button>
                <a
                  href="https://www.indiatoday.in/newsmo/video/hyderabad-artiste-anuj-gurwara-takes-a-dig-at-delhiites-braving-coldest-winter-in-10-years-2320770-2023-01-12"
                  target="_blank"
                >
                  Open Article
                </a>
              </button>
            </motion.div>

            <motion.div
              className="media-talented-s-card "
              custom={4}
              variants={imageVariants2}
            >
              {" "}
              <img src={Mediav5} alt="Mediav5" />
              <h3>
                Singer Anuj Gurwara’s ‘biryani message’ for Telangana voters
              </h3>
              <button>
                <a
                  href="https://telanganatoday.com/singer-anuj-gurwaras-biryani-mesage-for-telangana-voters"
                  target="_blank"
                >
                  Open Article
                </a>
              </button>
            </motion.div>
          </motion.div>
          <div className="image-box-bg">
            <div className="padding-1">
              <MediaGallery />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaPage;
