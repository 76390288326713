import React, { useState, useCallback } from "react";
import Singer from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { mediadata } from "./media.js";
import { useWindowSize } from "./useWindowSize";

function MediaGallery() {
  const { width } = useWindowSize();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  let columns;
  if (width < 600) {
    columns = 3;
  } else if (width < 830) {
    columns = 3;
  } else if (width < 1024) {
    columns = 3;
  } else if (width < 1280) {
    columns = 4;
  } else {
    columns = 4;
  }
  return (
    <div>
      {/* <Singer photos={mediadata} direction={"column"} columns={columns} /> */}

      <Singer
        photos={mediadata}
        direction={"column"}
        columns={columns}
        onClick={openLightbox}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={mediadata.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default MediaGallery;
