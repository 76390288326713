const TableData = {
  FilmographyTable: {
    id: 6,
    title: "Filmography Table",
    columns: [
      { name: "Year", selector: (row) => row.years },
      { name: "Show", selector: (row) => row.show },
      { name: "Role", selector: (row) => row.role },
      { name: "Language", selector: (row) => row.language },
      { name: "Platform", selector: (row) => row.platform },
    ],
    data: [
      {
        id: 1,
        years: "2004",
        show: "Hyderabad Blues 2",
        role: "Azam",
        language: "Hindi/English",
        platform: "Cinema",
      },
      {
        id: 2,
        years: "2004",
        show: "Anand",
        role: "Rahul Agarwal",
        language: "Telugu",
        platform: "Cinema",
      },
      {
        id: 3,
        years: "2004",
        show: "Centipede",
        role: "Ravi",
        language: "English",
        platform: "Cinema",
      },
      {
        id: 4,
        years: "2005",
        show: "Tango Charlie",
        role: "Soldier",
        language: "Hindi",
        platform: "Cinema",
      },
      {
        id: 5,
        years: "2010",
        show: "Maryada Ramanna",
        role: "Ghanshyamdas",
        language: "Telugu",
        platform: "Cinema",
      },
      {
        id: 6,
        years: "2013",
        show: "Daanav Hunters",
        role: "School Teacher",
        language: "Hindi",
        platform: "EPIC",
      },
      {
        id: 7,
        years: "2012",
        show: "Road To Paradise",
        role: "Travel Show Host",
        language: "English",
        platform: "Travel Trendz TV",
      },
      {
        id: 8,
        years: "2016",
        show: "24 (season 2)",
        role: "Hotel Guest",
        language: "Hindi",
        platform: "Colors TV",
      },
      {
        id: 9,
        years: "2017",
        show: "The Big Fat City",
        role: "Murali",
        language: "Hindi",
        platform: "Zee5",
      },
      {
        id: 10,
        years: "2021",
        show: "Dhamaka",
        role: "Manas Sethi",
        language: "Hindi",
        platform: "Netflix",
      },
      {
        id: 11,
        years: "2022",
        show: "Anya's Tutorial",
        role: "Anuj",
        language: "Telugu",
        platform: "aha",
      },
      {
        id: 12,
        years: "2024",
        show: "Bhamakalam 2",
        role: "Antony Lobo",
        language: "Telugu",
        platform: "aha",
      },
    ],
  },
};

export default TableData;
