import React from "react";
import FooterLogo from "../images/footer-logo.svg";
function Footer() {
  return (
    <div>
      <div className="wrapper">
        <div className="line-1"></div>
      </div>
      <div className="footer">
        <div className="wrapper">
          <img src={FooterLogo} alt="FooterLogo" className="FooterLogo" />

          <p>&copy; 2024.All Rights Reserved by Anuj Gurwara.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
