// src/images/images.js
import carousel1 from "./images/tb/trustbyimgs-1.png";
import carousel2 from "./images/tb/trustbyimgs-2.png";
import carousel3 from "./images/tb/trustbyimgs-3.png";
import carousel4 from "./images/tb/trustbyimgs-4.png";
import carousel5 from "./images/tb/trustbyimgs-5.png";
import carousel6 from "./images/tb/trustbyimgs-6.png";
import carousel7 from "./images/tb/trustbyimgs-7.png";
import carousel8 from "./images/tb/trustbyimgs-8.png";
import carousel9 from "./images/tb/trustbyimgs-9.png";
import carousel10 from "./images/tb/trustbyimgs-10.png";
import carousel11 from "./images/tb/trustbyimgs-11.png";
import carousel12 from "./images/tb/trustbyimgs-12.png";
import carousel13 from "./images/tb/trustbyimgs-13.png";
import carousel14 from "./images/tb/trustbyimgs-14.png";
import carousel15 from "./images/tb/trustbyimgs-15.png";
// import carousel16 from "./images/trustbyimgs-16.png";
// import carousel17 from "./images/trustbyimgs-17.png";
// import carousel18 from "./images/trustbyimgs-18.png";
// import carousel19 from "./images/trustbyimgs-19.png";
// import carousel20 from "./images/trustbyimgs-20.png";
// import carousel21 from "./images/trustbyimgs-21.png";
// import carousel22 from "./images/trustbyimgs-22.png";
// import carousel23 from "./images/trustbyimgs-23.png";
// import carousel24 from "./images/trustbyimgs-24.png";
// import carousel25 from "./images/trustbyimgs-25.png";
// import carousel26 from "./images/trustbyimgs-26.png";
// import carousel27 from "./images/trustbyimgs-27.png";
// import carousel28 from "./images/trustbyimgs-28.png";
// import carousel29 from "./images/trustbyimgs-29.png";
// import carousel30 from "./images/trustbyimgs-30.png";
// import carousel31 from "./images/trustbyimgs-31.png";
// import carousel32 from "./images/trustbyimgs-32.png";
// import carousel33 from "./images/trustbyimgs-33.png";
// import carousel34 from "./images/trustbyimgs-34.png";
// import carousel35 from "./images/trustbyimgs-35.png";
// import carousel36 from "./images/trustbyimgs-36.png";
// import carousel37 from "./images/trustbyimgs-37.png";
// import carousel38 from "./images/trustbyimgs-38.png";
// import carousel39 from "./images/trustbyimgs-39.png";
// import carousel40 from "./images/trustbyimgs-40.png";
// import carousel41 from "./images/trustbyimgs-41.png";
// import carousel42 from "./images/trustbyimgs-42.png";
// import carousel43 from "./images/trustbyimgs-43.png";
// import carousel44 from "./images/trustbyimgs-44.png";
// import carousel45 from "./images/trustbyimgs-45.png";
// import carousel46 from "./images/trustbyimgs-46.png";
// import carousel47 from "./images/trustbyimgs-47.png";
// import carousel48 from "./images/trustbyimgs-48.png";
// import carousel49 from "./images/trustbyimgs-49.png";
// import carousel50 from "./images/trustbyimgs-50.png";
// import carousel51 from "./images/trustbyimgs-51.png";
// import carousel52 from "./images/trustbyimgs-52.png";
// import carousel53 from "./images/trustbyimgs-53.png";
// import carousel54 from "./images/trustbyimgs-54.png";
// import carousel55 from "./images/trustbyimgs-55.png";
// import carousel56 from "./images/trustbyimgs-56.png";
// import carousel57 from "./images/trustbyimgs-57.png";
// import carousel58 from "./images/trustbyimgs-58.png";
// import carousel59 from "./images/trustbyimgs-59.png";
// import carousel60 from "./images/trustbyimgs-60.png";
// import carousel61 from "./images/trustbyimgs-61.png";
// import carousel62 from "./images/trustbyimgs-62.png";
// import carousel63 from "./images/trustbyimgs-63.png";
// import carousel64 from "./images/trustbyimgs-64.png";
// import carousel65 from "./images/trustbyimgs-65.png";
// import carousel66 from "./images/trustbyimgs-66.png";
// import carousel67 from "./images/trustbyimgs-67.png";
// import carousel68 from "./images/trustbyimgs-68.png";
// import carousel69 from "./images/trustbyimgs-69.png";
// import carousel70 from "./images/trustbyimgs-70.png";

const trustbyimgs = [
  { src: carousel1, alt: "carousel1" },
  { src: carousel2, alt: "carousel2" },
  { src: carousel3, alt: "carousel3" },
  { src: carousel4, alt: "carousel4" },
  { src: carousel5, alt: "carousel5" },
  { src: carousel6, alt: "carousel5" },
  { src: carousel7, alt: "carousel5" },
  { src: carousel8, alt: "carousel5" },
  { src: carousel9, alt: "carousel5" },
  { src: carousel10, alt: "carousel5" },
  { src: carousel11, alt: "carousel5" },
  { src: carousel12, alt: "carousel5" },
  { src: carousel13, alt: "carousel3" },
  { src: carousel14, alt: "carousel4" },
  { src: carousel15, alt: "carousel5" },
  // { src: carousel16, alt: "carousel5" },
  // { src: carousel17, alt: "carousel5" },
  // { src: carousel18, alt: "carousel5" },
  // { src: carousel19, alt: "carousel5" },
  // { src: carousel20, alt: "carousel5" },
  // { src: carousel31, alt: "carousel1" },
  // { src: carousel32, alt: "carousel2" },
  // { src: carousel33, alt: "carousel3" },
  // { src: carousel34, alt: "carousel4" },
  // { src: carousel35, alt: "carousel5" },
  // { src: carousel36, alt: "carousel5" },
  // { src: carousel37, alt: "carousel5" },
  // { src: carousel38, alt: "carousel5" },
  // { src: carousel39, alt: "carousel5" },
  // { src: carousel40, alt: "carousel5" },
  // { src: carousel41, alt: "carousel1" },
  // { src: carousel42, alt: "carousel2" },
  // { src: carousel43, alt: "carousel3" },
  // { src: carousel44, alt: "carousel4" },
  // { src: carousel45, alt: "carousel5" },
  // { src: carousel46, alt: "carousel5" },
  // { src: carousel47, alt: "carousel5" },
  // { src: carousel48, alt: "carousel5" },
  // { src: carousel49, alt: "carousel5" },
  // { src: carousel50, alt: "carousel5" },
  // { src: carousel51, alt: "carousel1" },
  // { src: carousel52, alt: "carousel2" },
  // { src: carousel53, alt: "carousel3" },
  // { src: carousel54, alt: "carousel4" },
  // { src: carousel55, alt: "carousel5" },
  // { src: carousel56, alt: "carousel5" },
  // { src: carousel57, alt: "carousel5" },
  // { src: carousel58, alt: "carousel5" },
  // { src: carousel59, alt: "carousel5" },
  // { src: carousel60, alt: "carousel5" },
  // { src: carousel61, alt: "carousel1" },
  // { src: carousel62, alt: "carousel2" },
  // { src: carousel63, alt: "carousel3" },
  // { src: carousel64, alt: "carousel4" },
  // { src: carousel65, alt: "carousel5" },
  // { src: carousel66, alt: "carousel5" },
  // { src: carousel67, alt: "carousel5" },
  // { src: carousel68, alt: "carousel5" },
  // { src: carousel69, alt: "carousel5" },
  // { src: carousel70, alt: "carousel5" },
];

export default trustbyimgs;
