const TableData = {
  PlaybacksingingAwards: {
    id: 7,
    title: "Playback singing - Awards",
    columns: [
      { name: "Award", selector: (row) => row.awards },
      { name: "Category", selector: (row) => row.category },
    ],
    data: [
      {
        id: 1,
        awards: "57th Filmfare Awards South",
        category: "Best Male Playback Singer (Telugu)",
      },
      {
        id: 2,
        awards: "MAA TV's CineMAA Awards",
        category: "Best Male Playback Singer",
      },
      {
        id: 3,
        awards: "South Scope",
        category: "Best Male Playback Singer Award",
      },
      {
        id: 4,
        awards: "Mirchi Music Awards South 2010",
        category: "Listener's Choice Best Song of the Year ",
      },
      {
        id: 5,
        awards: "Mirchi Music Awards South 2010",
        category: "Best Playback Singer (Nominated)",
      },
      {
        id: 6,
        awards: "Radio City 91.1 FM",
        category: "Best Playback Singer (Male)",
      },
      {
        id: 7,
        awards: "Radio City 91.1 FM",
        category: "Best Song of the Year",
      },
      {
        id: 8,
        awards: "Big 92.7 FM Ugadi Music Awards 2010",
        category: "Best Upcoming Singer of the Year",
      },
      {
        id: 9,
        awards: "Big 92.7 FM Ugadi Music Awards 2010",
        category: "Best Love Song of the Year",
      },
      {
        id: 10,
        awards: "Aalapana Music Awards 2010",
        category: "Best Upcoming Playback Singer",
      },
    ],
  },
};

export default TableData;
