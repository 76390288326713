export const mediadata = [
  {
    src: "../images/media/media-1.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-2.jpg",

    width: 4,
    height: 2,
  },
  {
    src: "../images/media/media-3.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-4.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-5.jpg",
    width: 1,
    height: 4,
  },
  {
    src: "../images/media/media-6.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../images/media/media-7.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../images/media/media-8.jpg",
    width: 4,
    height: 2,
  },
  {
    src: "../images/media/media-9.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-10.jpg",
    width: 2,
    height: 4,
  },
  {
    src: "../images/media/media-11.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-12.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-13.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-14.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-15.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-16.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-17.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-18.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-19.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-20.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-21.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-22.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-23.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-24.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-25.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-26.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-27.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-28.jpg",
    width: 4,
    height: 4,
  },

  {
    src: "../images/media/media-31.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-32.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-33.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-34.jpg",
    width: 4,
    height: 4,
  },
  {
    src: "../images/media/media-35.jpg",
    width: 4,
    height: 4,
  },
];
