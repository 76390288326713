import React from "react";
import Singer from "react-photo-gallery";
import { actorphotosdata } from "./actorphotos";
import { useWindowSize } from "./useWindowSize";

function ImgGalleryTwo() {
  const { width } = useWindowSize();
  let columns;
  if (width < 600) {
    columns = 2;
  } else if (width < 830) {
    columns = 3;
  } else if (width < 1024) {
    columns = 3;
  } else if (width < 1280) {
    columns = 4;
  } else {
    columns = 4;
  }

  return (
    <div>
      <Singer photos={actorphotosdata} direction={"row"} columns={columns} />
    </div>
  );
}

export default ImgGalleryTwo;
