import "./Styles.css";
import { Route, Routes } from "react-router-dom";
// src/App.js
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "./Components/Header/Header";
import Home from "./Components/Home";

import MasterofCeremonies from "./Components/MasterofCeremonies";
import Singer from "./Components/Singer";
import VoiceArtist from "./Components/VoiceArtist";
import MediaPage from "./Components/MediaPage";
import Actor from "./Components/Actor";
import CommunicationsCoach from "./Components/CommunicationsCoach";
import ContentCreator from "./Components/ContentCreator";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer/Footer";

function App() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    // Simulate a data fetch
    setTimeout(() => {
      setData({ message: "Data loaded!" });
      setLoading(false);
    }, 2000); // Adjust the timeout as needed
  }, []);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Person",
    name: "Anuj Gurwara",
    url: "https://anujgurwara.com",
    sameAs: [
      "https://www.linkedin.com/in/anujgurwara",
      "https://twitter.com/anujgurwara",
      "https://www.instagram.com/anujgurwara",
    ],
    jobTitle:
      "Master of Ceremonies, Singer, Voice Artist, Actor, Content Creator",
    worksFor: {
      "@type": "Organization",
      name: "Self-employed",
    },
    award: "Filmfare Award",
    description:
      "Anuj Gurwara is a seasoned MC, award-winning singer, experienced voice artist, actor, and content creator.",
  };

  return (
    <div className="App">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Helmet>
        <link rel="canonical" href="https://anujgurwara.com" />
      </Helmet>
      {loading ? (
        // loader start
        <div className="loader">
          {/* <div class="glass"></div> */}
          {/* <br />
          <br />
          <div class="loading-dots">
            <div class="dot-1"></div>
            <div class="dot-2"></div>
            <div class="dot-3"></div>
            <div class="dot-4"></div>
            <div class="dot-5"></div>
          </div>
          <br />
          <br /> */}
          <div className="spinner"></div>
          {/* <p>Loading...</p> */}
        </div>
      ) : (
        //  loader end
        <div>
          {/* content start */}
          <Header />
          <div className="main-section-wrap">
            <Routes>
              <Route element={<Home />} path="/" />
              <Route
                element={<MasterofCeremonies />}
                path="/Master-of-Ceremonies"
              />
              <Route element={<Singer />} path="/Singer" />
              <Route element={<MediaPage />} path="/Media" />
              <Route element={<Actor />} path="/Actor" />
              <Route element={<ContentCreator />} path="/Content-Creator" />
              <Route
                element={<CommunicationsCoach />}
                path="/Communications-Coach"
              />
              <Route element={<VoiceArtist />} path="/Voice-Artist" />

              {/* <Route element={<Media />} path="/Media" /> */}
              <Route element={<Contact />} path="/Contact" />
            </Routes>
          </div>
          <Footer />
          {/* content End */}
        </div>
      )}
    </div>
  );
}

export default App;
