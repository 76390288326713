import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ImgGallery from "./ImgGallery";
import SingerData from "./SingerData";
import PlaybacksingingAwardsTable from "./PlaybacksingingAwardsTable";

import ms1 from "./images/Singer-thumb-1.png";
import ms2 from "./images/Singer-thumb-2.png";
import ms3 from "./images/Singer-thumb-3.png";
import Awads from "./images/awads-1.png";

const tbldatanew = [
  {
    year: "2009",
    shows: ["Magadheera", "Pravarakhyudu"],
    songs: ["Panchadara Bomma", "Emaipoyano (O My Love)"],
    composers: ["M. M. Keeravani", "M. M. Keeravani"],
    languages: ["Telugu", "Telugu"],
  },
  {
    year: "2010",
    shows: [
      "Young India",
      "Vedam",
      "Bheemili Kabaddi Jattu",
      "Badmash",
      "Jhummandi Naadam",
      "Adi Nuvve",
      "Graduate",
      "26 Kingston",
    ],
    songs: [
      "Young India",
      "Prapancham Naaventosthunte",
      "Nidadholu Pilla (Jathara Song)",
      "Ninna Monna",
      "Yem Sakkagunavro",
      "Nuvvantene Ishtam",
      "Ninnala Nenu Lenu",
      "Nee Vallu Jadalo",
    ],
    composers: [
      "M. M. Keeravani",
      "M. M. Keeravani",
      "V. Selvaganesh",
      "Vandemataram Srinivas",
      "M. M. Keeravani",
      "Jeevan Thomas",
      "Sandeep",
      "Vishnu",
    ],
    languages: [
      "Telugu",
      "Telugu",
      "Telugu",
      "Telugu",
      "Telugu",
      "Telugu",
      "Telugu",
      "Telugu",
    ],
  },
  {
    year: "2011",
    shows: [
      "Anaganaga O Dheerudu",
      "Prema Kavali",
      "ATM",
      "Badrinaath",
      "Brahmigaadi Katha",
    ],
    songs: [
      "Ninnu Chudani",
      "Listen to My Heart",
      "Nuvve Nuvve",
      "Ambadari (Remix)",
      "Cheppaleni Maatayedho",
    ],
    composers: [
      "M. M. Keeravani",
      "Anoop Rubens",
      "Sukumar",
      "M. M. Keeravani",
      "Koti",
    ],
    languages: ["Telugu", "Telugu", "Telugu", "Telugu", "Telugu"],
  },
  {
    year: "2012",
    shows: [
      "SMS (Shiva Manasulo Shruti)",
      "Eega",
      "Telugabbayi",
      "Makkhi",
      "Makkhi",
    ],
    songs: [
      "Chikki Chikki Baby",
      "Lava Lava",
      "Churakathilanti",
      "Thoda Hans Ke",
      "Lava Lava",
    ],
    composers: [
      "V. Selvaganesh",
      "M. M. Keeravani",
      "Mejo Joseph",
      "MM Kreem",
      "MM Kreem",
    ],
    languages: ["Telugu", "Telugu", "Telugu", "Hindi", "Hindi"],
  },
  {
    year: "2019",
    shows: ["Independent Single"],
    songs: ["Amma Bawa"],
    composers: ["Sanket Sane"],
    languages: ["Dakhni"],
  },
  {
    year: "2021",
    shows: ["Independent Single"],
    songs: ["Tujh Jaisa Na Koi"],
    composers: ["Rishabh Ravi"],
    languages: ["Hindi"],
  },
  {
    year: "2023",
    shows: ["Independent Single"],
    songs: ["Bas Paanch Minute"],
    composers: ["Rishabh Ravi"],
    languages: ["Hindi"],
  },
];

const containerVariants = {
  hidden: { opacity: 0, y: "100px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6, // Duration of the transition in seconds
      ease: "easeOut", // Type of easing function
    },
  },
};

const talentedContainers2Variants = {
  hidden: { opacity: 0, y: "50px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "linear",
      duration: 2,
      x: { duration: 1 },
    },
  },
};

const imageVariants = {
  hover: { scale: 1.1, opacity: 0.8 },
};

const imageVariants2 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};

const imageVariants3 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};
function Singer() {
  const [refFirstGroup, inViewFirstGroup] = useInView({ threshold: 0.1 });
  const [refSecondGroup, inViewSecondGroup] = useInView({ threshold: 0.1 });
  const [refThirdGroup, inViewThirdGroup] = useInView({ threshold: 0.1 });
  const [refFourGroup, inViewFourGroup] = useInView({ threshold: 0.1 });
  const [refFiveGroup, inViewFiveGroup] = useInView({ threshold: 0.1 });
  const [refEightGroup, inViewEightGroup] = useInView({ threshold: 0.1 });
  const [refNineGroup, inViewNineGroup] = useInView({ threshold: 0.1 });

  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper font-style-1">
          <h1>Singer</h1>
          <p>
            {" "}
            A non-Telugu speaker, Anuj debuted in 2009 as Playback Singer for SS{" "}
            <i>Rajamouli's</i> magnum opus - <i>Magadheera</i>. Under the
            guidance of legendary Oscar-winning music director -
            <i>Mr. M M Keeravaani</i> - his debut song Panchadara Bomma became
            an instant chart topper in the months that followed the film's
            release.{" "}
          </p>
          <p>
            {" "}
            The song became a cult classic and brought home the{" "}
            <i>Filmfare Award</i> for <i>Best Playback Singer</i> apart from
            every other award of the year. Anuj went on to record songs for many
            more films, in genres ranging from love songs to hip hop.{" "}
          </p>
          <p>
            {" "}
            He has written, sung and produced his own originals as well
            including India's first ever Hyderabadi Pop Song Amma Bawa!{" "}
          </p>

          <div className="image-box-bg">
            <div className="padding-1">
              <ImgGallery />
            </div>
          </div>

          <div className="image-box-bg">
            <h2>Playback Singing</h2>
            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refSecondGroup}
                initial="hidden"
                animate={inViewSecondGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/qTE453yc1rc?si=bS2Ji2g2415TQ87R"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/NLRnshDleSc?si=TIKku0_OSrxOVBML"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/CiOq8mfMHNc?si=WA67TF7PJiTW0HSJ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>

          <div className="image-box-bg">
            <h2>Music Videos - Original Songs</h2>
            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refThirdGroup}
                initial="hidden"
                animate={inViewThirdGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/8pGGUCiqgA8?si=WyM8yi2-SvLyDqmm"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/9hjlZuukIS0?si=BFDSFfCCTFo21frZ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/sRVoBc_nX6w?si=LP1AY9aIRkB7mfRn"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>

          <div className="image-box-bg">
            <h2>Live Performance</h2>
            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refFirstGroup}
                initial="hidden"
                animate={inViewFirstGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/z8u7Orrhegg?si=9bwuvnsqvUEmxgmM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/ylQEgmMDMW8?si=kic_cFqsbZww0ACn"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/wJoYeqGtWJk?si=9B9JkH2ID4kTkBuf"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>

          <div className="image-box-bg">
            <h2>Photos</h2>

            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refEightGroup}
                initial="hidden"
                animate={inViewEightGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <img src={ms1} alt="ms1" />
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <img src={ms2} alt="ms2" />
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <img src={ms3} alt="ms3" />
                </motion.div>
              </motion.div>
            </div>
          </div>

          <div className="awards-gallery">
            <div className="">
              <h2>Awards</h2>

              <motion.div
                className="multi-talented-s-wrap"
                ref={refNineGroup}
                initial="hidden"
                animate={inViewNineGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div>
                  <img src={Awads} alt="Awads" />
                </motion.div>
              </motion.div>
            </div>
          </div>
          {/* <h2>SingerData</h2>
          <SingerData /> */}

          <hr></hr>
          <h2>Discography</h2>
          <div className="table-section table-style-1">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Film</th>
                  <th>Song</th>
                  <th>Composer</th>
                  <th>Language</th>
                </tr>
              </thead>
              <tbody>
                {tbldatanew.map((row, index) =>
                  row.shows.map((show, subIndex) => (
                    <tr key={`${index}-${subIndex}`}>
                      {subIndex === 0 && (
                        <td rowSpan={row.shows.length}>{row.year}</td>
                      )}
                      <td className="film-column">{show}</td>
                      <td className="song-column">{row.songs[subIndex]}</td>
                      <td className="composer-column">
                        {row.composers[subIndex]}
                      </td>
                      <td className="language-column">
                        {row.languages[subIndex]}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <hr></hr>

          <h2>Playback singing - Awards</h2>
          <PlaybacksingingAwardsTable />
          <hr></hr>
        </div>
      </div>
    </div>
  );
}

export default Singer;
