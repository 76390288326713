import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ImgGalleryTwo from "./ImgGalleryTwo";
import SingerData from "./SingerData";
import FilmographyTable from "./FilmographyTable";

import gallery1 from "./images/gallery-img-1.png";
import ms1 from "./images/ms-1.png";
import ms2 from "./images/ms-2.png";
import ms3 from "./images/ms-3.png";
import ms4 from "./images/ms-4.png";
import ms5 from "./images/ms-5.png";
import ms6 from "./images/ms-6.png";
import ms7 from "./images/ms-7.png";
import ms8 from "./images/ms-8.png";
import ms9 from "./images/ms-8.png";
import Awads from "./images/awads-1.png";

const containerVariants = {
  hidden: { opacity: 0, y: "100px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6, // Duration of the transition in seconds
      ease: "easeOut", // Type of easing function
    },
  },
};

const talentedContainers2Variants = {
  hidden: { opacity: 0, y: "50px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "linear",
      duration: 2,
      x: { duration: 1 },
    },
  },
};

const imageVariants = {
  hover: { scale: 1.1, opacity: 0.8 },
};

const imageVariants2 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};

const imageVariants3 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};
function Actor() {
  const [refFirstGroup, inViewFirstGroup] = useInView({ threshold: 0.1 });
  const [refSecondGroup, inViewSecondGroup] = useInView({ threshold: 0.1 });
  const [refThirdGroup, inViewThirdGroup] = useInView({ threshold: 0.1 });
  const [refFourGroup, inViewFourGroup] = useInView({ threshold: 0.1 });
  const [refFiveGroup, inViewFiveGroup] = useInView({ threshold: 0.1 });
  const [refEightGroup, inViewEightGroup] = useInView({ threshold: 0.1 });
  const [refNineGroup, inViewNineGroup] = useInView({ threshold: 0.1 });

  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper font-style-1">
          <h1>Actor</h1>
          <p>
            With years of training and experience in Theatre, Anuj has played an
            interesting set of characters on various platforms - stage,
            television and the big screen. One of his most memorable roles has
            been that of Rahul in Sekhar Kammula’s <i>Anand (2004)</i> - an
            indie telugu film that went on to become a cult hit.
          </p>

          <div className="image-box-bg">
            <h2>Posters</h2>
            <div className="padding-1">
              <ImgGalleryTwo />
            </div>
          </div>

          <div className="image-box-bg">
            <h2>Videos</h2>
            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refSecondGroup}
                initial="hidden"
                animate={inViewSecondGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/zEhEIkECxsU?si=8pEr3WQLVUv1RUeX"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/76lOQYi2nTs?si=vTs6OTelGxj47ZcL"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <iframe
                    title="vimeo-player"
                    src="https://player.vimeo.com/video/970612570?h=cbbedd0708"
                    width="100%"
                    height="315"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>

          <h2>Films / Television / OTT</h2>

          <div className="padding-1" style={{}}>
            <FilmographyTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Actor;
