export const actorphotosdata = [
  {
    src: "../images/actor-thumb-1.png",
    width: 3,
    height: 2,
  },
  {
    src: "../images/actor-thumb-2.png",
    width: 1,
    height: 1,
  },
  {
    src: "../images/actor-thumb-3.png",
    width: 3,
    height: 4,
  },
  {
    src: "../images/actor-thumb-4.png",
    width: 3,
    height: 4,
  },
  {
    src: "../images/actor-thumb-5.png",
    width: 3,
    height: 4,
  },
  {
    src: "../images/actor-thumb-6.png",
    width: 4,
    height: 4,
  },
  {
    src: "../images/actor-thumb-7.png",
    width: 2,
    height: 3,
  },
  {
    src: "../images/actor-thumb-8.png",
    width: 3,
    height: 3,
  },
  {
    src: "../images/actor-thumb-9.png",
    width: 3,
    height: 3,
  },
];
