import React from "react";
import { ResponsiveTable } from "responsive-table-react";

const columns = [
  {
    id: "film",
    text: "Film",
  },
  {
    id: "year",
    text: "Year",
  },

  {
    id: "song",
    text: "Song",
  },
  {
    id: "composer",
    text: "Composer",
  },
  {
    id: "language",
    text: "Language",
  },
];

const data = [
  {
    year: "2009",
    film: "Magadheera",
    song: "Panchadara Bomma",
    composer: "M. M. Keeravani",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "Young India",
    song: "Young India",
    composer: "M. M. Keeravani",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "Vedam",
    song: "Prapancham Naaventosthunte",
    composer: "M. M. Keeravani",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "Bheemili Kabaddi Jattu",
    song: "Nidadholu Pilla (Jathara Song)",
    composer: "V. Selvaganesh",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "Badmash",
    song: "Ninna Monna",
    composer: "Vandemataram Srinivas",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "Jhummandi Naadam",
    song: "Yem Sakkagunavro",
    composer: "M. M. Keeravani",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "Adi Nuvve",
    song: "Nuvvantene Ishtam",
    composer: "Jeevan Thomas",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "Graduate",
    song: "Ninnala Nenu Lenu",
    composer: "Sandeep",
    language: "Telugu",
  },
  {
    year: "2010",
    film: "26 Kingston",
    song: "Nee Vallu Jadalo",
    composer: "Vishnu",
    language: "Telugu",
  },

  {
    year: "2011",
    film: "Anaganaga O Dheerudu",
    song: "Ninnu Chudani",
    composer: "M. M. Keeravani",
    language: "Telugu",
  },
];

function SingerData() {
  return (
    <div>
      <ResponsiveTable columns={columns} data={data} />
    </div>
  );
}

export default SingerData;
