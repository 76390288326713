export const singerdata = [
  {
    src: "../images/singer-img-1.png",
    width: 3,
    height: 2,
  },
  {
    src: "../images/singer-img-2.png",
    width: 1,
    height: 1,
  },
  {
    src: "../images/singer-img-3.png",
    width: 3,
    height: 4,
  },
  {
    src: "../images/singer-img-4.png",
    width: 3,
    height: 4,
  },
  {
    src: "../images/singer-img-5.png",
    width: 3,
    height: 4,
  },
  {
    src: "../images/singer-img-6.png",
    width: 4,
    height: 4,
  },
  {
    src: "../images/singer-img-7.png",
    width: 2,
    height: 3,
  },
  {
    src: "../images/singer-img-8.png",
    width: 3,
    height: 3,
  },
  {
    src: "../images/singer-img-9.png",
    width: 3,
    height: 3,
  },
];
