import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import gallery1 from "./images/gallery-img-1.png";
import ms1 from "./images/ms-1.png";
import ms2 from "./images/ms-2.png";
import ms3 from "./images/ms-3.png";
import ms4 from "./images/ms-4.png";
import ms5 from "./images/ms-5.png";
import ms6 from "./images/ms-6.png";
import ms7 from "./images/ms-7.png";
import ms8 from "./images/ms-8.png";
import ms9 from "./images/ms-9.png";

const containerVariants = {
  hidden: { opacity: 0, y: "100px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6, // Duration of the transition in seconds
      ease: "easeOut", // Type of easing function
    },
  },
};

const talentedContainers2Variants = {
  hidden: { opacity: 0, y: "50px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "linear",
      duration: 2,
      x: { duration: 1 },
    },
  },
};

const imageVariants = {
  hover: { scale: 1.1, opacity: 0.8 },
};

const imageVariants2 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};

const imageVariants3 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};
function MasterofCeremonies() {
  const [refFirstGroup, inViewFirstGroup] = useInView({ threshold: 0.1 });
  const [refSecondGroup, inViewSecondGroup] = useInView({ threshold: 0.1 });
  const [refThirdGroup, inViewThirdGroup] = useInView({ threshold: 0.1 });
  const [refFourGroup, inViewFourGroup] = useInView({ threshold: 0.1 });
  const [refFiveGroup, inViewFiveGroup] = useInView({ threshold: 0.1 });

  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper">
          <h1>Master of Ceremonies</h1>
          <p>
            One of India’s leading Master of Ceremonies, Anuj Gurwara has ably
            shouldered expectations of clients & event planners - ranging from
            Corporates to Socials, Weddings to Award nights, Protocol Events
            featuring Heads of State, to some of the biggest names in the world
            of Business, Entertainment, and Sports.
          </p>
          <p>
            With a powerful stage presence, quick wit, command over language and
            a plethora of talents across genres of performing arts, Anuj brings
            a unique level of creative energy, engagement and sophistication to
            the stage.
          </p>
          <a
            href="images/Anuj-Gurwara-Profile.pdf"
            target="_blank"
            className="button-1"
          >
            DOWNLOAD PROFILE
          </a>

          <div className="image-box-bg">
            <h2>Images</h2>

            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refSecondGroup}
                initial="hidden"
                animate={inViewSecondGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <img src={ms1} alt="MasterCeremonies-1" />
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <img src={ms2} alt="MasterCeremonies-2" />
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <img src={ms3} alt="MasterCeremonies-3" />
                </motion.div>

                <motion.div className="image-box-1 " custom={3} variants={ms4}>
                  <img src={ms4} alt="MasterCeremonies-4" />
                </motion.div>

                <motion.div className="image-box-1 " custom={4} variants={ms5}>
                  <img src={ms5} alt="MasterCeremonies-5" />
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={5}
                  variants={imageVariants2}
                >
                  <img src={ms6} alt="MasterCeremonies-6" />
                </motion.div>

                <motion.div className="image-box-1 " custom={6} variants={ms7}>
                  <img src={ms7} alt="MasterCeremonies-7" />
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={7}
                  variants={imageVariants2}
                >
                  <img src={ms8} alt="MasterCeremonies-8" />
                </motion.div>
                <motion.div
                  className="image-box-1 "
                  custom={8}
                  variants={imageVariants2}
                >
                  <img src={ms9} alt="MasterCeremonies-9" />
                </motion.div>
              </motion.div>
            </div>
          </div>

          <div className="image-box-bg">
            <h2>Videos</h2>

            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refFirstGroup}
                initial="hidden"
                animate={inViewFirstGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/YWCeGLY2JN4?si=90TuKS8e_pBRgD_j"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/Iusz6D71yaA?si=NIwjdwXDei9H69iM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/aBnbjt3Z8_w?si=iEhNIlJ3OvuEOqQB"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterofCeremonies;
