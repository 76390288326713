import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { InstagramEmbed } from "react-social-media-embed";
import { LinkedInEmbed } from "react-social-media-embed";

import ImgGallery from "./ImgGallery";
import SingerData from "./SingerData";

import gallery1 from "./images/gallery-img-1.png";
import ms1 from "./images/ms-1.png";
import ms2 from "./images/ms-2.png";
import ms3 from "./images/ms-3.png";
import ms4 from "./images/ms-4.png";
import ms5 from "./images/ms-5.png";
import ms6 from "./images/ms-6.png";
import ms7 from "./images/ms-7.png";
import ms8 from "./images/ms-8.png";
import ms9 from "./images/ms-8.png";
import Awads from "./images/awads-1.png";

const containerVariants = {
  hidden: { opacity: 0, y: "100px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6, // Duration of the transition in seconds
      ease: "easeOut", // Type of easing function
    },
  },
};

const talentedContainers2Variants = {
  hidden: { opacity: 0, y: "50px" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "linear",
      duration: 2,
      x: { duration: 1 },
    },
  },
};

const imageVariants = {
  hover: { scale: 1.1, opacity: 0.8 },
};

const imageVariants2 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};

const imageVariants3 = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "linear",
      delay: index * 0.2, // Stagger effect
    },
  }),
};
function ContentCreator() {
  const [refFirstGroup, inViewFirstGroup] = useInView({ threshold: 0.1 });
  const [refSecondGroup, inViewSecondGroup] = useInView({ threshold: 0.1 });
  const [refThirdGroup, inViewThirdGroup] = useInView({ threshold: 0.1 });
  const [refFourGroup, inViewFourGroup] = useInView({ threshold: 0.1 });
  const [refFiveGroup, inViewFiveGroup] = useInView({ threshold: 0.1 });
  const [refEightGroup, inViewEightGroup] = useInView({ threshold: 0.1 });
  const [refNineGroup, inViewNineGroup] = useInView({ threshold: 0.1 });

  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper">
          <h1>Content Creator</h1>
          <p>
            Popular for his engaging storytelling style and unique perspectives,
            Anuj has been passionately presenting impactful stories across
            digital platforms, blending creativity with authenticity to inspire,
            entertain, and empower.
          </p>
          {/* <p>
            Content writer, scuba driver, traveller, foodie, tech-head, banking
            executives, farmer…one life is all we have.
          </p> */}
          <div className="socialmedia-sec-wrap">
            <div className="socialmedia-sec-1">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <InstagramEmbed
                  url="https://www.instagram.com/anujgurwara/"
                  width="100%"
                />
              </div>
            </div>
            <div className="socialmedia-sec-2">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LinkedInEmbed
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7205796262548234240"
                  postUrl="https://www.linkedin.com/embed/feed/update/urn:li:share:7205796262548234240"
                  width="100%"
                  //height="100%"
                />
              </div>
            </div>
          </div>
          <div className="image-box-bg">
            <h2>Youtube Portfolio</h2>
            <div className="image-box-wrap">
              <motion.div
                className="multi-talented-s-wrap"
                ref={refSecondGroup}
                initial="hidden"
                animate={inViewSecondGroup ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <motion.div
                  className="image-box-1 "
                  custom={0}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/kZYrNIFwVJg?si=_mVD8nL-xOQqIw6K"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={1}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/m7byNHWV2_U?si=BS7_ioiqmfTg2WwB"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>
                <motion.div
                  className="image-box-1 "
                  custom={2}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/QJFJ0YWEDYg?si=5sQ-tqe2yr2TjWr1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={3}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/HfZjXk0AQns?si=sv2koNKEojSnFLoy"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={4}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/JLD2JTRnrcg?si=wEwoMwEGhEqf9vVL"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>

                <motion.div
                  className="image-box-1 "
                  custom={5}
                  variants={imageVariants2}
                >
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/MAcuuCH-Ykc?si=zXT8lGDDW-PT9pGF"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>

          {/* <div className="image-box-bg">
            <h2>Contact Us</h2>
            <div className="padding-2">
              <p>
                Let’s connect on your project, either social or corporate. I’m
                available for freelance work as a master of ceremony, playback
                singer, actor, voice artist or educator. 
              </p>
              <p>Email: anujgurwara@gmail.com </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ContentCreator;
