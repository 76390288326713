// text.jsx

import React from "react";
import TableData from "./PlaybacksingingAwards"; // Adjust the path as necessary
// import "./Table.css";

const PlaybacksingingAwardsTable = () => {
  const Awards = TableData.PlaybacksingingAwards;
  const { columns, data, title } = Awards;

  return (
    <div className="table-container table-style-1">
      <div className="table-section">
       
        <table className="data-table">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{column.selector(item)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlaybacksingingAwardsTable;
